import React, { Component } from "react";
//import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { makeData } from "./Utils";
import matchSorter from "match-sorter";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-select/dist/react-select.css";

import "./react-datepicker.css";
// Import React Table
import ReactTable from "react-table";
import "./react-table.css";
import "./Home.css";
import StringUtils from "../StringUtils";
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      data: makeData(),
      notes: [],
      mtypes: [],
      sendNotifications: [],
      startDate: ""
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      // Helper.title = "Home";
      this.props.setTitle("HOME");
      return;
    }

    try {
      //   alert("yahan");
      //Helper.title = "Notification History";
      this.props.setTitle("NOTIFICATION HISTORY");
      const notes = await this.notifsend();
      // const mtypes = await this.mtypes();
      // this.setState({ notes });
    } catch (e) {
      alert(e);
    }

    this.setState({ isLoading: false });
  }

  notifsend() {
    //AUTH
    //return API.get("notes", "/notes");
    return fetch(StringUtils.IP + "/AllNotificationSend", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        //  alert(JSON.stringify(responseJson));
        var temp = [];
        responseJson.forEach(data => {
          // console.warn(data);
          temp.push({
            notificationTitle: data.NotificationTitle,
            notificationContent: data.NotificationContent,
            notificationMessage: data.NotificationMessage,
            datetime: data.SentDateTime,
            topic: data.topic,
            TopicType: data.TopicType
          });
        });

        this.setState({ sendNotifications: temp });
        //alert(this.state.notes.length);
      })
      .catch(() => {
        // this.setState({ isLoading: false }, () => {
        //   alert(JSON.stringify(error));
        // });
      });
  }
  mtypes() {
    return fetch(StringUtils.IP + "/AllMusicType", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        //  alert(JSON.stringify(responseJson));
        this.setState({ mtypes: responseJson });
        //alert(this.state.notes.length);
      })
      .catch(() => {
        // this.setState({ isLoading: false }, () => {
        //   alert(JSON.stringify(error));
        // });
      });
  }

  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };
  checkValue(option) {
    //  alert("aaya to");
    var v = false;
    this.state.sendNotifications.forEach(data => {
      // console.warn(data);
      alert(data.TopicType);
      if (data.TopicType === option) {
        v = true;
      } else {
        v = false;
      }
    });
    return v;
  }
  renderNotesList() {
    // <ListGroupItem
    //   key={note.pk_NotificationTopicsID}
    //   href={`/notes/${note.pk_NotificationTopicsID}`}
    //   onClick={this.handleNoteClick}
    //   header={note.NotificationDescription.trim().split("\n")[0]}
    // >
    //   <div class="list fw-feed-item-content-module">
    //     <h5 class="fw-feed-item-title heading">{note.NotificationTopic}</h5>

    //     <span class="fw-feed-item-description">
    //       {note.NotificationDescription.trim().split("\n")[0]}
    //     </span>
    //     {/* <br />
    //     <span class="fw-feed-item-date">Feb 25, 2020 1:02 AM</span> */}
    //   </div>
    // </ListGroupItem>

    return (
      <div>
        {/* <ListGroupItem
          key="new"
          href="/notificationtopics/new"
          onClick={this.handleNoteClick}
        >
          <h5 class="add">
            <b>{"\uFF0B"}</b> Send Notification
          </h5>
        </ListGroupItem> */}
        <ReactTable
          style={{ backgroundColor: "#fff" }}
          data={this.state.sendNotifications}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Notification Title",
              // accessor: "notificationTitle",
              // filterMethod: (filter, row) =>
              //   row[filter.id].startsWith(filter.value)
              id: "notificationTitle",
              accessor: "notificationTitle",
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["notificationTitle"]
                }),
              filterAll: true
            },
            {
              Header: "Notification Message",
              id: "notificationMessage",
              accessor: "notificationMessage",
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["notificationMessage"]
                }),
              filterAll: true
              // filterMethod: (filter, row) =>
              //   row[filter.id].startsWith(filter.value)
            },
            {
              Header: "Notification Content",
              id: "notificationContent",
              accessor: "notificationContent",
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["notificationContent"]
                }),
              filterAll: true
              // filterMethod: (filter, row) =>
              //   row[filter.id].startsWith(filter.value)
            },
            {
              Header: "Date & Time",
              accessor: "datetime",
              Cell: ({ value }) => value,
              filterMethod: (filter, row) => {
                var valueDate = filter.value.split("-");
                var month = valueDate[1];
                var day = valueDate[2];
                var year = valueDate[0];
                var fulldate = month + "/" + day + "/" + year;
                var filterdate = row[filter.id].substring(0, 10);
                //  alert(filterdate. + "  " + fulldate);
                return filterdate + "" === fulldate + "";
              },
              Filter: ({ onChange }) => (
                // <DatePicker
                //   // selected={"startDate"}
                //   // onChange={date => setStartDate(date)}
                //   showTimeSelect
                //   timeFormat="HH:mm"
                //   timeIntervals={15}
                //   timeCaption="time"
                //   dateFormat="MMMM d, yyyy h:mm aa"
                // />

                <input
                  style={{ width: "100%" }}
                  type="date"
                  placeholder="Select Date"
                  onChange={event => onChange(event.target.value)}
                  // onChange={event =>
                  //   this.setState({ startDate: event.target.value })
                  // }
                  // value={filter ? filter.value : "all"}
                />
              )
            },
            {
              Header: "Notification Topics",
              accessor: "topic",
              id: "over",
              Cell: ({ value }) => value.split("|")[0],
              filterMethod: (filter, row) => {
                if (filter.value === "all") {
                  return true;
                }
                if (filter.value === "true") {
                  return row[filter.id].split("|")[1] === "Notify Me";
                }
                return row[filter.id].split("|")[1] === "My Music";
              },
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={event => onChange(event.target.value)}
                  style={{ width: "100%" }}
                  value={filter ? filter.value : "all"}
                >
                  <option value="all">Show All</option>
                  <option value="true">Notify Me</option>
                  <option value="false">My Music</option>
                </select>
              )
            }
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <br />
      </div>
    );
  }
  renderMusicTypeList(mtypes) {
    return [{}].concat(mtypes).map((mtype, i) =>
      i !== 0 ? (
        <ListGroupItem
          key={mtype.pk_MusicTypeID}
          href={`/notes/${mtype.pk_MusicTypeID}`}
          onClick={this.handleNoteClick}
          header={mtype.MusicTopic.trim().split("\n")[0]}
        >
          <div class="list fw-feed-item-content-module">
            <h5 class="fw-feed-item-title heading">{mtype.TypeName}</h5>

            <span class="fw-feed-item-description">
              {mtype.MusicTopic.trim().split("\n")[0]}
            </span>
            {/* <br />
            <span class="fw-feed-item-date">Feb 25, 2020 1:02 AM</span> */}
          </div>
        </ListGroupItem>
      ) : (
        <ListGroupItem
          key="new"
          href="/notificationtopics/new"
          onClick={this.handleNoteClick}
        >
          <h5 class="add">
            <b>{"\uFF0B"}</b> Create a new Music Type
          </h5>
        </ListGroupItem>
      )
    );
  }

  renderLander() {
    return (
      <div>
        <div className="lander">
          <img
            src={require("../images/dashboard_logo.png")}
            width="420"
            height="106"
            className="d-inline-block align-top ban"
            alt=""
          />
        </div>
        <div className="lander">
          <h1>BYB Notifications</h1>
          <p>Backyardbend Subscription Messages Manager</p>
          <div>
            <Link to="/login" className="btn btn-info btn-lg">
              Login
            </Link>
            {/* <Link to="/signup" className="btn btn-success btn-lg">
            Signup
          </Link> */}
          </div>
        </div>
      </div>
    );
  }

  renderNotes() {
    return (
      <div className="notes">
        {/* <Navbar.Brand bg="light">
          <Navbar.Brand style={{ color: "#16AEE7" }}>
            <h3> Notifications History</h3>
          </Navbar.Brand>
        </Navbar.Brand> */}
        <ListGroup>{!this.state.isLoading && this.renderNotesList()}</ListGroup>
      </div>
    );
  }
  notesAndMusicType() {}
  render() {
    return (
      <div className="Home">
        {this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
      </div>
    );
  }
}
