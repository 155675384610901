import React, { Component, Fragment } from "react";
//import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import {
  Nav,
  Navbar,
  NavItem} from "react-bootstrap";
// import Navbar from "react-bootstrap/Navbar";
// // import {Navbar as well } from "react-bootstrap"
// import Nav from "react-bootstrap/Nav";
// import NavItem from "react-bootstrap/NavItem";
import Routes from "./Routes";
import "./App.css";
import {ToastsContainer, ToastsStore} from 'react-toasts';
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      title: "Home"
    };
  }
  handleNoteClick = event => {
    event.preventDefault();
    this.props.history.push(event.currentTarget.getAttribute("href"));
  };
  async componentDidMount() {
    try {
      //AUTH
      // if (await Auth.currentSession()) {
      // this.userHasAuthenticated(true);
      // }
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };
  setTitle = title => {
    this.setState({ title: title });
  };
  handleLogout = async () => {
    //Authh
    // await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/login");
  };
  addNewNotification = async () => {
    //Authh
    // await Auth.signOut();
    //this.userHasAuthenticated(false);
  };
  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      setTitle: this.setTitle
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App container">
          <Navbar className="banner bg-light justify-content-between">
            <Nav style={{ flex: 1 }}>
              <Navbar.Brand
                href="/"
                className="justify-content-start"
                onClick={this.handleNoteClick}
              >
                <img
                  src={require("./images/home.png")}
                  width="33"
                  height="30"
                  className="d-inline-block align-top"
                  alt=""
                />
              </Navbar.Brand>
              {this.state.isAuthenticated ? (
                <Navbar.Brand
                  key="new"
                  href="/notificationtopics/new"
                  onClick={this.handleNoteClick}
                >
                  <img
                    src={require("./images/addnotification.png")}
                    width="25"
                    height="30"
                    className="d-inline-block align-top"
                    alt=""
                  />
                </Navbar.Brand>
              ) : null}
            </Nav>

            <Navbar.Collapse
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                style={{ marginRight: 10 }}
                src={require("./images/header_logo.png")}
                width="33"
                height="30"
                className="d-inline-block align-top"
                alt=""
              />
              <Navbar.Text style={{ color: "#F86F34", fontSize: 20 }}>
                {" " + this.state.title}
              </Navbar.Text>
            </Navbar.Collapse>
            <Navbar.Collapse
              style={{ flex: 1 }}
              className="justify-content-end"
            >
              <Nav>
                {this.state.isAuthenticated ? (
                  <Fragment>
                    <NavItem onClick={this.handleLogout}>
                      <img
                        src={require("./images/logout.png")}
                        width="27"
                        height="30"
                        className="d-inline-block align-top"
                        alt=""
                      />
                    </NavItem>
                  </Fragment>
                ) : null}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <Routes childProps={childProps} />
          <ToastsContainer store={ToastsStore} />
        </div>
      )
    );
  }
}

export default withRouter(App);
