import React, { Component } from "react";
import { Link } from "react-router-dom";
//import { Auth } from "aws-amplify";
import { Form } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Login.css";
import { ToastsStore } from "react-toasts";
import StringUtils from "../StringUtils";
export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      data: []
    };
  }
  componentDidMount() {
    // this.loadData();
    // Helper.title = "Login";
    this.props.setTitle("LOGIN");
  }
  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      // await Auth.signIn(this.state.email, this.state.password);
      // this.props.userHasAuthenticated(true);
      // alert(
      //   this.state.email + " " + this.state.password + " " + this.state.data
      // );
      this.login();
    } catch (e) {
      //  alert(e.message);
      ToastsStore.error("An error has occured :" + e.message);
      this.setState({ isLoading: false });
    }
  };
  // ADD NOTIFICATION TOPIC AND MUSIC TYPE START
  // addMusicType() {
  //   return fetch("http://localhost:4001/MusicType", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       IsActive: 1,
  //       MusicTopic: "Entertainment",
  //       TypeName: "JAZZ"
  //     })
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       this.setState({ isLoading: false }, () => {
  //         // this.parseLoginResponse(responseJson);
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({ isLoading: false }, () => {
  //         alert(JSON.stringify(error));
  //       });
  //     });
  // }
  // addNotificatioTopics() {
  //   return fetch("http://localhost:4001/NotificationTopics", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({
  //       IsActive: 1,
  //       NotificationDescription:
  //         "Event notifications send information about events to a Service Broker service.",
  //       NotificationTopic: "Events Notification"
  //     })
  //   })
  //     .then(response => response.json())
  //     .then(responseJson => {
  //       this.setState({ isLoading: false }, () => {
  //         // this.parseLoginResponse(responseJson);
  //       });
  //     })
  //     .catch(error => {
  //       this.setState({ isLoading: false }, () => {
  //         alert(JSON.stringify(error));
  //       });
  //     });
  // }
  // ADD NOTIFICATION TOPIC AND MUSIC TYPE END
  login() {
    return fetch(StringUtils.IP + "/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        username: this.state.email,
        password: this.state.password
      })
    })
      .then(response => response.json())
      .then(responseJson => {
        this.setState({ isLoading: false }, () => {
          this.parseLoginResponse(responseJson);
        });
      })
      .catch(() => {
        this.setState({ isLoading: false }, () => {
          // alert(JSON.stringify(error));
          ToastsStore.error("Something went wrong");
        });
      });
  }
  parseLoginResponse(responseJson) {
    if (responseJson.statusCode === "200") {
      this.props.userHasAuthenticated(true);
      // this.addMusicType();
      // this.addNotificatioTopics();
    } else if (responseJson.statusCode === "404") {
      //  alert(JSON.stringify(responseJson.responseMessage));
      ToastsStore.error(JSON.stringify(responseJson.responseMessage));
    } else if (responseJson.statusCode === "400") {
      //  alert(JSON.stringify(responseJson.responseMessage));
      ToastsStore.error(JSON.stringify(responseJson.responseMessage));
    }
  }
  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <Form.Group controlId="email" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
              style={{
                backgroundColor: "#fff",
                color: "#5E696E",
                borderColor: "#353D43",
                borderWidth: 1
              }}
            />
          </Form.Group>
          <Form.Group controlId="password" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>Password</Form.Label>
            <Form.Control
              style={{
                backgroundColor: "#fff",
                color: "#5E696E",
                borderColor: "#353D43",
                borderWidth: 1
              }}
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </Form.Group>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
        </form>
      </div>
    );
  }
}
