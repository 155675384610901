import React, { Component } from "react";
//import { API } from "aws-amplify";
import { InputGroup, Form } from "react-bootstrap";
import { ToastsStore } from "react-toasts";
import LoaderButton from "../components/LoaderButton";
import "./NewNotificationTopic.css";
import { RadioGroup, RadioButton } from "react-radio-buttons";
import { DropdownList } from "react-widgets";
import "react-widgets/dist/css/react-widgets.css";

import StringUtils from "../StringUtils";
export default class NewNotificationTopic extends Component {
  constructor(props) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: null,
      content: "",
      notiftitle: "",
      notifadurl: "",
      notifadimageurl: "",
      notifcontent: "",
      notifmessage: "",
      notifcontenttype: "plaintext",
      NotificationTopic: "",
      NotificationTopicVal: "",
      NotificationTopicType: "",
      notifresourceurl: "",
      screen_name_url: "",
      MusicType: "",
      errormessage: false,
      mtypes: [],
      notes: [],
      title_chars_left: 75,
      message_chars_left: 150,
      url_chars_left: 75,
      image_url_chars_left: 150,
      notifresourceurl_left: 75,
      content_chars_left: 1000,
      isChecked: false,
      isInfSrcURL: false
    };
    this.handleChecked = this.handleChecked.bind(this); // set this,
    this.selectScreenOptions = this.selectScreenOptions.bind(this);
  }

  createNote() {
    //AUTH
    // return API.post("notes", "/notes", {
    //   body: note
    // });
  }
  htmlValidatot(html) {
    var openingTags, closingTags;

    html = html.replace(/<[^>]*\/\s?>/g, ""); // Remove all self closing tags
    html = html.replace(/<(br|hr|img).*?>/g, ""); // Remove all <br>, <hr>, and <img> tags
    openingTags = html.match(/<[^\/].*?>/g) || []; // Get remaining opening tags
    closingTags = html.match(/<\/.+?>/g) || []; // Get remaining closing tags

    return openingTags.length === closingTags.length ? true : false;
  }
  componentDidMount() {
    this.notes();
    this.props.setTitle("SEND NOTIFICATION");
    // window.addEventListener("focus", this.onFocus);
  }
  componentWilUnmount() {
    //window.removeEventListener("focus", this.onUnFocus);
  }
  onFocus = () => {
    alert("focus");
  };
  onUnFocus = () => {
    alert("un focus");
  };
  checkZero(data) {
    if (data.length == 1) {
      data = "0" + data;
    }
    return data;
  }
  addNotificatioSend(
    notificationtitle,
    notificationcontent,

    topic,
    notificationtopictype,
    SubscriptionType,
    NotificationMessage,
    NotificationContentType,
    AdUrl,
    InformationSourceURL,
    notifadimageurl
  ) {
    var today = new Date();
    var day = today.getDate() + "";
    var month = today.getMonth() + 1 + "";
    var year = today.getFullYear() + "";
    var hour = today.getHours() + "";
    var minutes = today.getMinutes() + "";
    var seconds = today.getSeconds() + "";

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);
    hour = this.checkZero(hour);
    minutes = this.checkZero(minutes);
    seconds = this.checkZero(seconds);

    var compDateTime =
      month +
      "/" +
      day +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;

    return fetch(StringUtils.IP + "/NotificationSend", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        NotificationTitle: notificationtitle,
        NotificationContent: notificationcontent,

        SentDateTime: compDateTime,
        topic: topic,
        TopicType: notificationtopictype,
        SubscriptionType: SubscriptionType,
        NotificationMessage: NotificationMessage,
        NotificationContentType: NotificationContentType,
        AdUrl: AdUrl,
        MoreDetailsEnable: this.state.isChecked ? "1" : "0",
        ScreenToOpen: this.state.isChecked ? InformationSourceURL : "nill",
        AdImageUrl: notifadimageurl
      })
    })
      .then(response => response.json())
      .then(() => {
        this.setState({ isLoading: false }, () => {
          // this.parseLoginResponse(responseJson);
          ToastsStore.success("Notification Successfully Sent!");
          this.props.history.push("/");
        });
      })
      .catch(() => {
        this.setState({ isLoading: false }, () => {
          ToastsStore.error("An error has occured");
        });
      });
  }
  notes() {
    //AUTH
    //return API.get("notes", "/notes");
    return fetch(StringUtils.IP + "/AllNotificationTopics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        //  alert(JSON.stringify(responseJson));

        var temp = [];
        responseJson.forEach((data, i) => {
          // console.warn(data);
          temp.push({
            key: i,
            value: data.NotificationDescription,
            topic: data.NotificationTopic,
            details: data.NotificationDetails
          });
        });
        this.setState({ notes: temp }, () => {
          this.mtypes();
        });
        //alert(this.state.notes.length);
      })
      .catch(() => {
        // this.setState({ isLoading: false }, () => {
        //   alert(JSON.stringify(error));
        // });
      });
  }
  mtypes() {
    return fetch(StringUtils.IP + "/AllMusicType", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        //  alert(JSON.stringify(responseJson));

        var temp = [];
        responseJson.forEach((data, i) => {
          // console.warn(data);
          temp.push({
            key: i,
            value: data.TypeName,
            topic: data.MusicTopic,
            details: ""
          });
        });
        this.setState({ mtypes: temp });
        //alert(this.state.notes.length);
      })
      .catch(() => {
        // this.setState({ isLoading: false }, () => {
        //   alert(JSON.stringify(error));
        // });
      });
  }

  validateForm() {
    var chk = this.state.isChecked
      ? this.state.notifresourceurl.length > 0
      : true;
    return (
      this.state.notiftitle.length > 0 &&
      this.state.notifcontent.length > 0 &&
      this.state.notifmessage.length > 0 &&
      this.state.notifadurl.length > 0 &&
      this.state.notifadimageurl.length > 0 &&
      chk &&
      this.state.NotificationTopic.length > 0
    );
  }
  roughSizeOfObject(object) {
    var objectList = [];
    var stack = [object];
    var bytes = 0;

    while (stack.length) {
      var value = stack.pop();

      if (typeof value === "boolean") {
        bytes += 4;
      } else if (typeof value === "string") {
        bytes += value.length * 2;
      } else if (typeof value === "number") {
        bytes += 8;
      } else if (
        typeof value === "object" &&
        objectList.indexOf(value) === -1
      ) {
        objectList.push(value);

        for (var i in value) {
          stack.push(value[i]);
        }
      }
    }
    return bytes;
  }
  returnErrorMessage() {
    if (this.state.errormessage) {
      if (this.state.notifcontenttype === "url") {
        return (
          <div>
            <p>Please enter correct URL &nbsp;</p>
          </div>
        );
      } else if (this.state.notifcontenttype === "html") {
        return (
          <div>
            <p>
              Please enter correct html code or validate your html on &nbsp;
              <a href="https://jsonformatter.org/html-validator">
                HTML Validator
              </a>
            </p>
          </div>
        );
      }
    } else {
      return (
        <div>
          <p>&nbsp;</p>
        </div>
      );
    }
  }
  checkInputCharacters(id) {
    var charCount = 0;
    var charLeft = 0;
    if (id === "notiftitle") {
      charCount = this.state.notiftitle.length;
      charLeft = 75 - charCount;
      this.setState({ title_chars_left: charLeft });
    } else if (id === "notifmessage") {
      charCount = this.state.notifmessage.length;
      charLeft = 150 - charCount;
      this.setState({ message_chars_left: charLeft });
    } else if (id === "notifadurl") {
      charCount = this.state.notifadurl.length;
      charLeft = 75 - charCount;
      this.setState({ url_chars_left: charLeft });
    } else if (id === "notifadimageurl") {
      charCount = this.state.notifadimageurl.length;
      charLeft = 150 - charCount;
      this.setState({ image_url_chars_left: charLeft });
    } else if (id === "notifcontent") {
      charCount = this.state.notifcontent.length;
      charLeft = 1000 - charCount;
      this.setState({ content_chars_left: charLeft });
    } else if (id === "notifresourceurl") {
      charCount = this.state.notifresourceurl.length;
      charLeft = 75 - charCount;
      this.setState({ notifresourceurl_left: charLeft });
    }
  }
  sizeValidator(id, val) {
    if (id === "notiftitle") {
      return val.length <= 75;
    } else if (id === "notifmessage") {
      return val.length <= 150;
    } else if (id === "notifadurl") {
      return val.length <= 75;
    } else if (id === "notifadimageurl") {
      return val.length <= 150;
    } else if (id === "notifcontent") {
      return val.length <= 1000;
    } else if (id === "notifresourceurl") {
      return val.length <= 75;
    }
  }
  handleChange = event => {
    // alert(event.target.id + " " + event.target.value);
    var id = event.target.id;
    var val = event.target.value;
    if (this.sizeValidator(id, val)) {
      if (this.state.notifcontenttype === "html") {
        this.setState(
          {
            [event.target.id]: event.target.value
          },
          () => {
            this.checkInputCharacters(id);
            if (this.state.notifcontent.length > 0) {
              if (!this.checkHTML()) {
                this.setState({
                  errormessage: true
                });
              } else if (!this.htmlValidatot(this.state.notifcontent)) {
                this.setState({
                  errormessage: true
                });
              } else {
                this.setState({ errormessage: false });
              }
            }
          }
        );
      } else if (this.state.notifcontenttype === "url") {
        this.setState(
          {
            [event.target.id]: event.target.value
          },
          () => {
            this.checkInputCharacters(id);
            if (this.state.notifcontent.length > 0) {
              if (this.validURL(this.state.notifcontent)) {
                this.setState({
                  errormessage: false
                });
              } else {
                this.setState({
                  errormessage: true
                });
              }
            }
          }
        );
      } else {
        this.setState(
          {
            [event.target.id]: event.target.value
          },
          () => {
            this.checkInputCharacters(id);
          }
        );
      }
    } else {
      this.sizeValidatorMessage(id);
    }
  };
  sizeValidatorMessage(id) {
    if (id === "notiftitle") {
      ToastsStore.error("Notification Title cannot more than 75 characters");
    } else if (id === "notifmessage") {
      ToastsStore.error("Notification Message cannot more than 150 characters");
    } else if (id === "notifadurl") {
      ToastsStore.error("Advertisement URL cannot more than 75 characters");
    } else if (id === "notifadimageurl") {
      ToastsStore.error(
        "Advertisement Image URL cannot more than 75 characters"
      );
    } else if (id === "notifcontent") {
      ToastsStore.error(
        "Notification Content cannot more than 1000 characters"
      );
    } else if (id === "notifresourceurl") {
      ToastsStore.error(
        "Information source URL cannot more than 75 characters"
      );
    }
  }
  validateInfoSrcURL() {
    if (this.state.isInfSrcURL) {
      return this.validURL(this.state.notifresourceurl);
    } else {
      return true;
    }
  }
  validURL(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    //  alert(!!pattern.test(str))
    return !!pattern.test(str);
  }
  handleFileChange = event => {
    this.file = event.target.files[0];
  };
  checkHTML() {
    return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(
      this.state.notifcontent
    );
  }
  handleSubmit = async event => {
    event.preventDefault();
    // var sizeof = require("object-sizeof");
    // var params = {
    //   notification: {
    //     title: "T",
    //     body: "B"
    //   },
    //   data: {
    //     S: "03/04/2020 17:10:28",
    //     T: "upcomingconcertsevents",
    //     Y: "ConcertsAndEvents",
    //     N: "0",
    //     C: "A",
    //     U: "H"
    //   }
    // };
    // alert(
    //   JSON.stringify(params) +
    //     "\n" +
    //     "dataS03/04/2020 17:10:28TupcomingconcertseventsConcertsAndEventsN0CAUH  = 71 characters" +
    //     "\n" +
    //     sizeof(params) +
    //     " Bytes"
    // );
    // if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
    //   alert(
    //     `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
    //       750000} MB.`
    //   );
    //   return;
    // }

    if (
      this.state.notifcontenttype === "html" &&
      this.checkHTML() &&
      this.htmlValidatot(this.state.notifcontent) &&
      this.validURL(this.state.notifadurl) &&
      this.validURL(this.state.notifadimageurl) &&
      this.validImageURL(this.state.notifadimageurl) &&
      this.validateInfoSrcURL()
    ) {
      this.setState({ isLoading: true });
      this.addNotificatioSend(
        this.state.notiftitle,
        this.state.notifcontent,

        this.state.NotificationTopicVal +
          "|" +
          this.state.NotificationTopicType +
          "|" +
          this.state.NotificationTopic,
        this.state.NotificationTopicType,
        this.state.NotificationTopic,
        this.state.notifmessage,
        this.state.notifcontenttype,
        this.state.notifadurl,
        this.state.notifresourceurl,
        this.state.notifadimageurl
      );
    } else if (
      this.state.notifcontenttype === "plaintext" &&
      !this.checkHTML() &&
      this.validURL(this.state.notifadurl) &&
      this.validURL(this.state.notifadimageurl) &&
      this.validImageURL(this.state.notifadimageurl) &&
      this.validateInfoSrcURL()
    ) {
      this.setState({ isLoading: true });
      this.addNotificatioSend(
        this.state.notiftitle,
        this.state.notifcontent,

        this.state.NotificationTopicVal +
          "|" +
          this.state.NotificationTopicType +
          "|" +
          this.state.NotificationTopic,
        this.state.NotificationTopicType,
        this.state.NotificationTopic,
        this.state.notifmessage,
        this.state.notifcontenttype,
        this.state.notifadurl,
        this.state.notifresourceurl,
        this.state.notifadimageurl
      );
    } else if (
      this.state.notifcontenttype === "url" &&
      !this.checkHTML() &&
      this.validURL(this.state.notifadurl) &&
      this.validURL(this.state.notifadimageurl) &&
      this.validImageURL(this.state.notifadimageurl) &&
      this.validateInfoSrcURL()
    ) {
      this.setState({ isLoading: true });
      this.addNotificatioSend(
        this.state.notiftitle,
        this.state.notifcontent,

        this.state.NotificationTopicVal +
          "|" +
          this.state.NotificationTopicType +
          "|" +
          this.state.NotificationTopic,
        this.state.NotificationTopicType,
        this.state.NotificationTopic,
        this.state.notifmessage,
        this.state.notifcontenttype,
        this.state.notifadurl,
        this.state.notifresourceurl,
        this.state.notifadimageurl
      );
    } else {
      if (!this.validURL(this.state.notifadurl)) {
        ToastsStore.error("Advertisment URL not valid");
      } else if (!this.validURL(this.state.notifadimageurl)) {
        ToastsStore.error("Advertisment Image URL not valid");
      } else if (!this.validImageURL(this.state.notifadimageurl)) {
        ToastsStore.error(
          "Advertisment Image URL must be end with .jpg .jpeg .png or .gif"
        );
      } else if (!this.validateInfoSrcURL()) {
        ToastsStore.error("Information source URL not valid");
      } else {
        ToastsStore.error(
          this.state.notifcontenttype === "plaintext"
            ? "Notification content must be Plaintext"
            : "Notification content must be HTML"
        );
      }
    }

    try {
      // const attachment = this.file ? await s3Upload(this.file) : null;
      // await this.createNote({
      //   attachment,
      //   content: this.state.content
      // });
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };
  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked }, () => {
      if (!this.state.isChecked) {
        this.setState({
          screen_name_url: "",
          isInfSrcURL: false,
          notifresourceurl: ""
        });
      }
      // alert(this.state.notifresourceurl.length);
    });
  }
  validImageURL(notifadimageurl) {
    if (notifadimageurl.endsWith(".jpg")) {
      return true;
    } else if (notifadimageurl.endsWith(".jpeg")) {
      return true;
    } else if (notifadimageurl.endsWith(".png")) {
      return true;
    } else if (notifadimageurl.endsWith(".gif")) {
      return true;
    } else {
      return false;
    }
  }
  selectScreenOptions = event => {
    if (event.name === "Information source URL") {
      this.setState({
        screen_name_url: event.name,
        isInfSrcURL: true,
        notifresourceurl: ""
      });
    } else {
      this.setState({
        screen_name_url: event.name,
        isInfSrcURL: false,
        notifresourceurl: event.name
      });
    }
  };
  onChange = value => {
    console.log(value);
    //alert(value);
    if (value === "html") {
      this.setState({ notifcontenttype: value }, () => {
        if (this.state.notifcontent.length > 0) {
          if (!this.checkHTML()) {
            this.setState({
              errormessage: true
            });
          } else if (!this.htmlValidatot(this.state.notifcontent)) {
            this.setState({
              errormessage: true
            });
          } else {
            this.setState({ errormessage: false });
          }
        }
      });
    } else if (value === "url") {
      this.setState({ notifcontenttype: value }, () => {
        if (this.state.notifcontent.length > 0) {
          if (this.validURL(this.state.notifcontent)) {
            this.setState({
              errormessage: false
            });
          } else {
            this.setState({
              errormessage: true
            });
          }
        }
      });
    } else {
      this.setState({ notifcontenttype: value, errormessage: false });
    }

    // alert(value);
  };
  getColon(text) {
    if (text === "") {
      return "";
    } else {
      return ": ";
    }
  }
  render() {
    var data = [];
    data.push({ name: "Notify Me", objects: this.state.notes });
    data.push({ name: "My Music", objects: this.state.mtypes });

    const newData = [];
    data.forEach(element => {
      const name = element.name;
      if (element.objects && element.objects.length) {
        element.objects.forEach(keyValueData => {
          newData.push({
            name,
            key: keyValueData.key,
            value: keyValueData.value,
            topic: keyValueData.topic,
            details: keyValueData.details
          });
        });
      }
    });
    let GroupHeading = ({ item }) => (
      <h4 style={{ color: "#16AEE7" }}>{item}</h4>
    );
    let ListItem = ({ item }) => (
      <span>
        <strong>{item.value + this.getColon(item.details)}</strong>
        {" " + item.details}
      </span>
    );
    let options = [
      { id: 0, name: "Conditions" },
      { id: 1, name: "Digital Deals" },
      { id: 2, name: "Concerts & Events" },
      { id: 3, name: "What’s Trending" },

      { id: 4, name: "Positive News" },
      { id: 5, name: "Win it" },
      { id: 6, name: "Information source URL" }
    ];
    return (
      <div className="NewNotificationTopic">
        <form onSubmit={this.handleSubmit}>
          <Form.Group controlId="text" bsSize="large" style={{ marginTop: 20 }}>
            <Form.Label style={{ color: "#7A7A7A" }}>
              Notification Topic
            </Form.Label>
            {/* <Multiselect data={newData} textField="value" groupBy="name" /> */}
            <DropdownList
              style={{
                color: "#fff",
                borderColor: "#353D43",
                borderWidth: 1
              }}
              filter
              data={newData}
              groupComponent={GroupHeading}
              itemComponent={ListItem}
              groupBy="name"
              value={this.state.NotificationTopicVal}
              allowCreate="onFilter"
              // onCreate={name => this.handleCreate(name)}
              onChange={value =>
                this.setState({
                  NotificationTopic: value.topic,
                  NotificationTopicVal: value.value,
                  NotificationTopicType: value.name
                })
              }
              textField="value"
            />
          </Form.Group>
          {/* <Form.Group controlId="text" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>Music Type</Form.Label>
            <DropdownList
              style={{
                color: "#fff",
                borderColor: "#353D43",
                borderWidth: 1
              }}
              filter
              data={this.state.mtypes}
              value={this.state.MusicType}
              allowCreate="onFilter"
              // onCreate={name => this.handleCreate(name)}
              onChange={value => this.setState({ MusicType: value })}
              textField="name"
            />
          </Form.Group> */}
          <Form.Group controlId="notiftitle" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Notification Title
            </Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                aria-describedby="ntitle"
                value={this.state.notiftitle}
                onChange={this.handleChange}
                style={{
                  backgroundColor: "#F7F6F5",
                  color: "#5E696E",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text id="ntitle">
                  {this.state.title_chars_left + " /75"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="notifmessage" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Notification Message
            </Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                value={this.state.notifmessage}
                onChange={this.handleChange}
                aria-describedby="nmessage"
                style={{
                  backgroundColor: "#F7F6F5",
                  color: "#5E696E",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text id="nmessage">
                  {this.state.message_chars_left + " /150"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="notifadimageurl" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Advertisement Image URL
            </Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                aria-describedby="nadimageurl"
                value={this.state.notifadimageurl}
                onChange={this.handleChange}
                style={{
                  backgroundColor: "#F7F6F5",
                  color: "#5E696E",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
              />{" "}
              <InputGroup.Append>
                <InputGroup.Text id="nadimageurl">
                  {this.state.image_url_chars_left + " /150"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="notifadurl" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Advertisement URL
            </Form.Label>
            <InputGroup>
              <Form.Control
                autoFocus
                type="text"
                aria-describedby="nadurl"
                value={this.state.notifadurl}
                onChange={this.handleChange}
                style={{
                  backgroundColor: "#F7F6F5",
                  color: "#5E696E",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
              />{" "}
              <InputGroup.Append>
                <InputGroup.Text id="nadurl">
                  {this.state.url_chars_left + " /75"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="notifcheck" bsSize="large">
            <Form.Check
              bsSize="large"
              style={{ color: "#7A7A7A" }}
              type="checkbox"
              onChange={this.handleChecked}
              label="More details enable"
            />
          </Form.Group>
          {this.state.isChecked ? (
            <Form.Group
              controlId="notifcheckoptions"
              bsSize="large"
              style={{ marginTop: 20 }}
            >
              <Form.Label style={{ color: "#7A7A7A" }}>
                Screen to Open on Click
              </Form.Label>
              {/* <Multiselect data={newData} textField="value" groupBy="name" /> */}
              <DropdownList
                style={{
                  color: "#fff",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
                data={options}
                valueField="id"
                textField="name"
                defaultValue={1}
                value={this.state.screen_name_url}
                // onCreate={name => this.handleCreate(name)}
                onChange={this.selectScreenOptions}
              />
            </Form.Group>
          ) : null}
          {this.state.isChecked ? (
            this.state.isInfSrcURL ? (
              <Form.Group controlId="notifresourceurl" bsSize="large">
                <Form.Label style={{ color: "#7A7A7A" }}>
                  Information source URL
                </Form.Label>
                <InputGroup>
                  <Form.Control
                    autoFocus
                    type="text"
                    aria-describedby="nresourceurl"
                    value={this.state.notifresourceurl}
                    onChange={this.handleChange}
                    style={{
                      backgroundColor: "#F7F6F5",
                      color: "#5E696E",
                      borderColor: "#353D43",
                      borderWidth: 1
                    }}
                  />{" "}
                  <InputGroup.Append>
                    <InputGroup.Text id="nresourceurl">
                      {this.state.notifresourceurl_left + " /75"}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
            ) : null
          ) : null}
          <Form.Group controlId="notifcontenttype" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Notification Content Type
            </Form.Label>
            <RadioGroup
              value={this.state.notifcontenttype}
              onChange={this.onChange}
              horizontal
            >
              <RadioButton
                rootColor={"#7A7A7A"}
                pointColor={"#00b73d"}
                iconSize={20}
                value="plaintext"
              >
                Plain Text
              </RadioButton>
              <RadioButton
                pointColor={"#00b73d"}
                rootColor={"#7A7A7A"}
                iconSize={20}
                value="html"
              >
                HTML
              </RadioButton>

              <RadioButton
                pointColor={"#00b73d"}
                rootColor={"#7A7A7A"}
                iconSize={20}
                value="url"
              >
                URL
              </RadioButton>
            </RadioGroup>
          </Form.Group>
          <Form.Group controlId="notifcontent" bsSize="large">
            <Form.Label style={{ color: "#7A7A7A" }}>
              Notification Content
            </Form.Label>
            <InputGroup>
              <Form.Control
                style={{
                  backgroundColor: "#F7F6F5",
                  color: "#5E696E",
                  borderColor: "#353D43",
                  borderWidth: 1
                }}
                value={this.state.notifcontent}
                onChange={this.handleChange}
                type="text"
                as="textarea"
                rows="3"
                aria-describedby="ncontent"
              />
              <InputGroup.Append>
                <InputGroup.Text id="ncontent">
                  {this.state.content_chars_left + " /1000"}
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
            <Form.Label style={{ color: "red", flex: 1 }}>
              {this.returnErrorMessage()}
            </Form.Label>
          </Form.Group>

          <LoaderButton
            title={
              !this.validateForm()
                ? "You must fill in all of the fields as per limitations!"
                : "Send Notification"
            }
            block
            bsSize="large"
            className={
              !this.validateForm()
                ? "NewNotifLoaderButtonD"
                : "NewNotifLoaderButtonE"
            }
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Send"
            loadingText="Sending…"
          />
        </form>
      </div>
    );
  }
}
